import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/Aboutus";
import Casestudy from "./pages/Casestudy";
import News from "./pages/OnCoNews";
import Contactus from "./pages/Contactus";
import NotFound from "./pages/Notfound";
import CHeader from "./components/header";
import Login from "./pages/Login/Login";
import Uploadfiles from "./pages/Files/Uploadfiles";
import FolderList from "./pages/Files/FolderList";
import Files from "./pages/Files/Files";
import UserList from "./pages/Admin/Userlist";
import AskDrQuestion from "./pages/Admin/AskDrQuestions";
import Symptoms from "./pages/Admin/Symptoms";
import TargetedTherapy from "./pages/Admin/TargetedTherapy";
import Teams from "./pages/Admin/Team";
import OncoNews from "./pages/Admin/OncoNews";
import KnowYourDNA from "./pages/Admin/KnowYourDNA";
import Landing from "./pages/Admin/Landing";
import Feedback from "./pages/Admin/Feedbacks";
import { FileBrowser } from "./pages/FileExplorer";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/aboutus",
    element: <About />,
  },
  {
    path: "/casestudy",
    element: <Casestudy />,
  },
  {
    path: "/onconews",
    element: <News />,
  },
  {
    path: "/contactus",
    element: <Contactus />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/head",
    element: <CHeader />,
  },
  {
    path: "/uploadfiles",
    element: <Uploadfiles />,
  },
  {
    path: "/fileexplorer",
    element: <FolderList />,
  },
  {
    path: "/files/:folderId",
    element: <Files />,
  },
  {
    path: "/filebrowser/:folderId?",
    element: <FileBrowser />,
  },
]);
export const adminRouter = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/feedbacks",
    element: <Feedback />,
  },
  {
    path: "/patients",
    element: <UserList kind="Patient" />,
  },
  {
    path: "/doctors",
    element: <UserList kind="Physician" />,
  },
  {
    path: "/caregivers",
    element: <UserList kind="Caregiver" />,
  },
  {
    path: "/askdrQuestion",
    element: <AskDrQuestion />,
  },
  {
    path: "/symptomsQuestion",
    element: <Symptoms />,
  },
  {
    path: "/targetedTherapy",
    element: <TargetedTherapy />,
  },
  {
    path: "/team",
    element: <Teams />,
  },
  {
    path: "/oncoNews",
    element: <OncoNews />,
  },
  {
    path: "/knowyourdna",
    element: <KnowYourDNA />,
  },
]);
