import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import { ToastContainer, toast } from "react-toastify";

export const getFilesList = createAsyncThunk(
  "files/getFilesList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/files/filesJSON`);
      return res.data.data?.files || [];
    } catch (err) {
      return rejectWithValue(
        "Error while getting questions list please Try Again Later"
      );
    }
  }
);
export const getFileAcessURL = createAsyncThunk(
  "files/getFileAcessURL",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/files/accessURL/${data.id}`);
      data.callback(res.data.data);
      return;
    } catch (err) {
      console.log("Error in getting file access URL", err);
      data.callback(null);
      return rejectWithValue(
        "Error while getting questions list please Try Again Later"
      );
    }
  }
);
export const getFolderList = createAsyncThunk(
  "files/getFolderList",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.get(`/files/folderlist`);
      dispatch(getFilesList());
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting list", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while deleting category please Try Again Later"
      );
    }
  }
);
export const renameFile = createAsyncThunk(
  "files/rename",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/files/rename`, data);
      toast.success("Renamed the file successfully.", {
        position: "bottom-center",
      });
      setTimeout(() => {
        dispatch(getFolderList());
      }, 2000);

      return res.data.data;
    } catch (err) {
      toast.error("Error in renaming file", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while renaming file please Try Again Later"
      );
    }
  }
);

export const moveFile = createAsyncThunk(
  "files/move",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/files/move`, data);
      toast.success("Moved the file successfully.", {
        position: "bottom-center",
      });
      setTimeout(() => {
        dispatch(getFolderList());
      }, 2000);

      return res.data.data;
    } catch (err) {
      toast.error("Error in moving file", {
        position: "bottom-center",
      });
      return rejectWithValue("Error while moving file please Try Again Later");
    }
  }
);
export const copyFile = createAsyncThunk(
  "files/copy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/files/copy`, data);
      toast.success("Copied the file successfully.", {
        position: "bottom-center",
      });
      setTimeout(() => {
        dispatch(getFolderList());
      }, 2000);

      return res.data.data;
    } catch (err) {
      toast.error("Error in copying file", {
        position: "bottom-center",
      });
      return rejectWithValue("Error while copying file please Try Again Later");
    }
  }
);

export const newFolder = createAsyncThunk(
  "files/newFolder",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/files/newFolder`, data);
      toast.success("Created the folder successfully.", {
        position: "bottom-center",
      });
      setTimeout(() => {
        dispatch(getFolderList());
      }, 2000);
      // dispatch(getFilesList());
      return res.data.data;
    } catch (err) {
      toast.error("Error in creating folder", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while creating folder please Try Again Later"
      );
    }
  }
);

export const deleteFileFolder = createAsyncThunk(
  "files/delete",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.delete(`/files`, data);
      toast.success("Deleted the file successfully.", {
        position: "bottom-center",
      });
      setTimeout(() => {
        dispatch(getFolderList());
      }, 2000);

      return res.data.data;
    } catch (err) {
      toast.error("Error in deleting file", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while deleting file please Try Again Later"
      );
    }
  }
);

const initialState = {
  files: {
    loading: false,
    data: [],
  },
  folder: {
    loading: false,
    data: [],
  },
};

export const fileExpSlice = createSlice({
  name: "fileExplorer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFilesList.fulfilled, (state, action) => {
        state.files.data = action.payload;
        state.files.loading = false;
      })
      .addCase(getFilesList.pending, (state, action) => {
        state.files.loading = true;
      })
      .addCase(getFilesList.rejected, (state, action) => {
        state.files.loading = false;
      })
      .addCase(getFolderList.fulfilled, (state, action) => {
        state.folder.data = action.payload;
        state.folder.loading = false;
      })
      .addCase(getFolderList.pending, (state, action) => {
        state.folder.loading = true;
      })
      .addCase(getFolderList.rejected, (state, action) => {
        state.folder.loading = false;
      });
  },
});

export const {} = fileExpSlice.actions;
export default fileExpSlice.reducer;
