import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFolderList } from "../../redux/documentsSlice";
import { FolderOutlined } from "@ant-design/icons";
import { Route, useNavigate } from "react-router-dom";

function FolderList() {
  const dispatch = useDispatch();
  const documentState = useSelector((state) => state.documents);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getFolderList());
  }, []);
  const getFiles = (folderId) => {
    // dispatch(getFilesList({ folderId: folderId }));
    return navigate(`/files/${folderId}`);
  };
  return (
    <section style={{ background: "white !important", height: "100vh" }}>
      <div className="container" style={{ marginTop: "5vh" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {documentState.folderList.data.map((item) => (
            <span
              style={{ padding: "20px", cursor: "pointer" }}
              onClick={() => getFiles(item._id)}
            >
              <FolderOutlined
                twoToneColor="#124b81"
                style={{ fontSize: "5rem", color: "#124b81" }}
              />
              <p>{item.folderName}</p>
            </span>
            // <Option value={item._id}>{item.folderName}</Option>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FolderList;
