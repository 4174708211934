import React from "react";
import ContactUSImg from '../../assets/images/ContactUS1.svg'
import './index.css'
import aboutBg from "../../assets/images/about-bg.png";

function Contactus() {
  return (
    <>
      <section className="page-header-section">
        <div className="container page-header-container-divided">
          <div className="page-header-content">
            <p className="page-header-badge">Get in Touch with Us</p>
            <h1 className="xl-text b-weight inverted-text r-b-m">
              We&apos;ve set out to power purpose in every business worldwide
            </h1>
            <p className="r-text inverted-text ah-op">
            We are here to help. Whether it’s to learn more about our industry-leading digital oncology solutions or if you’re interested in partnering with us, please submit the form below.
            </p>
          </div>
          <div className="page-header-img-container">
            <img src={aboutBg} alt="background" className="about-bg" />
          </div>
        </div>
      </section>

    
      <div className="container footer-contact-content">
        <form name="contactForm" id="contactForm">
          <div className="section-header">
            <p className="secHeader">How can we help you?</p>
          </div>
          <div className="footer-contact-divided-group">
            <div>
              <label>First Name</label>
              <input type="text" className="text-input" required />
            </div>
            <div>
              <label>Last Name</label>
              <input type="text" className="text-input" required />
            </div>
          </div>
          <div className="footer-contact-divided-group">
            <div>
              <label>Phone Number</label>
              <input type="text" className="text-input" required />
            </div>
            <div>
              <label>Email</label>
              <input type="email" className="text-input" required />
            </div>
          </div>
          <div className="footer-contact-group">
            <label>Message</label>
            <textarea rows="3" className="message-input"></textarea>
          </div>
          <div className="footer-submit-group">
            <button type="submit" className="submitBtn form-btn">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Contactus;
