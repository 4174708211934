import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getQuestions,
  updateQuestions,
  createNewSubCategory,
  createNewCategory,
} from "../../../redux/symptomsSlice";
import {
  DeleteOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Select,
  Collapse,
  Card,
  Button,
  Modal,
  Alert,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { Input } from "antd";
const { TextArea } = Input;

const answerTypes = ["radio", "YN", "text"];
const defoptions = [
  "Yes",
  "No",
  "None",
  "Mild",
  "Moderate",
  "Severe",
  "Very Severe",
  "Never",
  "Rarely",
  "Occasionally",
  "Frequently",
  "Almost constantly",
];

function Symptoms() {
  const dispatch = useDispatch();
  const symptom = useSelector((state) => state.symptoms);
  const [selectedCat, setSelectedCat] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [newSubCat, setNewSubCat] = useState(null);
  const [newCatName, setNewCatName] = useState(null);
  const [isNewCatModalVisible, setIsNewCatModalVisible] = useState(false);
  const [editCat, setEditCat] = useState(null);
  const [catName, setCatName] = useState([]);
  const [buttonKind, setButtonKind] = useState(null);

  useEffect(() => {
    dispatch(getQuestions());
  }, []);
  useEffect(() => {
    if (symptom.questionList.data.length > 0) {
      console.log("adasdas");
      setQuestionsList(symptom.questionList.data);
    }
  }, [symptom.questionList.data]);

  const onCatChange = (val) => {
    console.log(val);
    let subCats = symptom.questionList.data.filter((item) => item._id === val);
    setSelectedCat(subCats[0].subCategories);
    setCatName(subCats);
  };
  const saveEditedQuestions = () => {
    dispatch(updateQuestions({ updateData: editedQuestions }));
  };
  const changeQuestionValue = (
    question_id,
    value,
    sub_cat,
    sub_cat_id,
    kind,
    type
  ) => {
    let temp = JSON.parse(JSON.stringify(selectedCat));
    let index = temp.findIndex((item) => item._val === sub_cat);

    let index2 = temp[index]._ques.findIndex(
      (item) => item._id === question_id
    );
    temp[index]._ques[index2][type] = value;
    temp[index]._ques[index2].isActive = kind === "delete" ? false : true;
    temp[index]._ques[index2].sub_cat_id = sub_cat_id;
    temp[index]._ques[index2].cat_id = catName[0]._id;
    temp[index]._ques[index2].isNew = false;
    console.log("temp", temp[index]._ques[index2]);
    setSelectedCat(temp);
    setEditCat(sub_cat);
    let _editedQuestion = [...editedQuestions];
    // Need to persist the values
    let existing = _editedQuestion.filter(
      (item) => item.question_id === question_id
    );
    let existingIndex = _editedQuestion.findIndex(
      (item) => item.question_id === question_id
    );
    let toIns = {};
    if (existing.length > 0) {
      toIns = existing[0];
      toIns[type] = value;
      delete existing[existingIndex];
    } else {
      toIns = {
        question_id: question_id,
        [type]: value,
        isActive: kind === "delete" ? false : true,
        sub_cat_id: sub_cat_id,
        cat_id: catName[0]._id,
      };
      _editedQuestion.push(toIns);
    }
    setEditedQuestions(_editedQuestion);
  };
  const genExtra = (item) =>
    editedQuestions !== null &&
    editedQuestions.length > 0 &&
    item._val === editCat ? (
      <SaveOutlined
        onClick={() => {
          console.log("editedQuestions", editedQuestions);
          saveEditedQuestions();
        }}
      />
    ) : null;

  const addNewQuestion = (sub_cat_id) => {
    if (sub_cat_id === null) {
      console.log("New Sub Cat is crated");
    } else {
      let category_id = catName[0]._id;
      console.log("sub_cat_id", sub_cat_id, category_id, selectedCat);
      let temp = JSON.parse(JSON.stringify(selectedCat));
      let index = temp.findIndex((item) => item._id === sub_cat_id);
      temp[index]._ques.push({
        question: "",
        isActive: true,
        sub_category: temp[index]._val,
        category_id: category_id,
        sub_cat_id: sub_cat_id,
        answerType: null,
        options: null,
        _id: `NEW_${new Date().getTime()}`,
        isNew: true,
      });

      setSelectedCat(temp);
    }
  };
  const addNewCategory = () => {
    if (buttonKind === "subcat") {
      dispatch(
        createNewSubCategory({ category_id: catName[0]._id, _val: newSubCat })
      );
      setSelectedCat([]);
      setCatName([]);
    } else {
      dispatch(createNewCategory({ category: newCatName }));
    }
    handleNewCategoryCancel();
  };
  const handleNewCategoryCancel = () => {
    setIsNewCatModalVisible(false);
    setNewSubCat(null);
    setNewCatName(null);
  };
  const handleModalButtonClick = (kind) => {
    setButtonKind(kind);
    setIsNewCatModalVisible(true);
  };
  return (
    <>
      <Card title={`Symptoms`} style={{ margin: 15, height: "87vh" }}>
        <Modal
          title={`Add New ${buttonKind === "subcat" ? "Sub" : ""} Category`}
          open={isNewCatModalVisible}
          onOk={addNewCategory}
          onCancel={handleNewCategoryCancel}
        >
          <Input
            placeholder={`Name of new  ${
              buttonKind === "subcat" ? "Sub" : ""
            } Category`}
            value={buttonKind === "subcat" ? newSubCat : newCatName}
            onChange={(e) =>
              buttonKind === "subcat"
                ? setNewSubCat(e.target.value)
                : setNewCatName(e.target.value)
            }
          />
        </Modal>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
            <div
              style={{
                display: "flex",
                //   justifyContent: "center",
                margin: "10px",
              }}
            >
              <Select
                style={{ width: "100%" }}
                options={
                  symptom.questionList &&
                  symptom.questionList.data.map((item) => {
                    return { label: item.category, value: item._id };
                  })
                }
                value={catName[0] && catName[0]._id}
                size="large"
                placeholder="Select a symptom category"
                onChange={onCatChange}
                loading={symptom.questionList.loading}
              ></Select>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
            <Button
              onClick={() => handleModalButtonClick("cat")}
              icon={<PlusCircleOutlined />}
              size={"medium"}
              // style={{ margin: 10,marginRight:0 }}
            >
              Category
            </Button>
          </Col>
          <Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
            <Button
              onClick={() => handleModalButtonClick("subcat")}
              icon={<PlusCircleOutlined />}
              size={"medium"}
              // style={{ margin: 10,marginLeft:0 }}
            >
              Sub Category
            </Button>
          </Col>
        </Row>
        {selectedCat.length > 0 ? (
          <Card
            style={{
              width: "95%",
              margin: "1%",
              overflow: "auto",
              maxHeight: "90vh",
            }}
          >
            {selectedCat.map((question, index) => (
              <div key={index}>
                <Collapse
                  collapsible="header"
                  items={[
                    {
                      key: "1",
                      label: <p>{question._val}</p>,
                      children: (
                        <div>
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Button
                              onClick={() => addNewQuestion(question._id)}
                              icon={<PlusCircleOutlined />}
                              size={"medium"}
                              style={{ margin: 10 }}
                            >
                              Question
                            </Button>

                            {editedQuestions !== null &&
                            editedQuestions.length > 0 &&
                            question._val === editCat ? (
                              <Button
                                onClick={() => {
                                  saveEditedQuestions();
                                }}
                                type="primary"
                                icon={<SaveOutlined />}
                                size={"medium"}
                                style={{ margin: 10 }}
                              >
                                Save
                              </Button>
                            ) : null}
                          </div>
                          {question._ques.map((item, index) =>
                            item.isActive === false ? null : (
                              <div
                                style={{
                                  background:
                                    item.isActive === false
                                      ? "#ffcab6"
                                      : "white",
                                  padding: 10,
                                  borderRadius: 5,
                                }}
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  key={index}
                                >
                                  <Col
                                    xs={20}
                                    sm={20}
                                    md={20}
                                    lg={23}
                                    xl={23}
                                    xxl={23}
                                  >
                                    <TextArea
                                      placeholder="Add your question here."
                                      autoSize={{
                                        minRows: 2,
                                        maxRows: 6,
                                      }}
                                      disabled={!item.isActive}
                                      value={item.question}
                                      onChange={(e) =>
                                        changeQuestionValue(
                                          item._id,
                                          e.target.value,
                                          question._val,
                                          question._id,
                                          "edit",
                                          "question"
                                        )
                                      }
                                      style={{ width: "97%" }}
                                    />
                                  </Col>
                                  <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={1}
                                    xl={1}
                                    xxl={1}
                                  >
                                    <Popconfirm
                                      title="Delete the question"
                                      description="Are you sure to delete this question?"
                                      onConfirm={() =>
                                        item.isActive
                                          ? changeQuestionValue(
                                              item._id,
                                              item.question,
                                              question._val,
                                              question._id,
                                              "delete",
                                              "question"
                                            )
                                          : console.log("Already deleted")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        style={{ fontSize: 20 }}
                                      />
                                    </Popconfirm>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: 5 }}>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                  >
                                    <div>
                                      Answer Type: &nbsp;
                                      <Select
                                        options={answerTypes.map((item) => {
                                          return { label: item, value: item };
                                        })}
                                        placeholder="Please select"
                                        style={{ width: "80%" }}
                                        disabled={!item.isActive}
                                        onChange={(e) =>
                                          changeQuestionValue(
                                            item._id,
                                            e,
                                            question._val,
                                            question._id,
                                            "edit",
                                            "answerType"
                                          )
                                        }
                                        value={{
                                          label: item.answerType,
                                          value: item.answerType,
                                        }}
                                      ></Select>
                                    </div>
                                  </Col>
                                  {item.answerType !== "text" && (
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={14}
                                      xl={14}
                                      xxl={14}
                                    >
                                      <div>
                                        Options &nbsp;
                                        <Select
                                          mode="multiple"
                                          disabled={!item.isActive}
                                          placeholder="Please select"
                                          value={item.options}
                                          style={{ width: "80%" }}
                                          onChange={(e) =>
                                            changeQuestionValue(
                                              item._id,
                                              e,
                                              question._val,
                                              question._id,
                                              "edit",
                                              "options"
                                            )
                                          }
                                          options={defoptions.map((item) => {
                                            return {
                                              label: item,
                                              value: item,
                                            };
                                          })}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <br />
                              </div>
                            )
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
                <br />
              </div>
            ))}
          </Card>
        ) : (
          <div style={{ marginTop: "25vh", width: "30%", marginLeft: "25vw" }}>
            <Alert
              message="Select Category"
              description="Please select a symptom category"
              type="info"
              showIcon
            />
          </div>
        )}
      </Card>
    </>
  );
}

export default Symptoms;
