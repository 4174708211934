import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFeedbacks } from "../../../redux/feedbackSlice";
import { Button, Modal, Input, Form, Card, Popconfirm } from "antd";
import moment from "moment";
import { Table } from "antd";

function Feedback() {
  const dispatch = useDispatch();
  const feedbackState = useSelector((state) => state.feedback);
  const [tableData, setTableData] = useState([]);

  React.useEffect(() => {
    dispatch(getFeedbacks());
  }, []);
  const columns = [
    {
      title: "Feedback",
      dataIndex: "feedback",
      sorter: (a, b) => a.feedback.localeCompare(b.feedback),
    },
    {
      title: "Email",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
    },
  ];

  React.useEffect(() => {
    let _data = [];
    if (feedbackState?.feedbacks?.data.length > 0) {
      let temp = JSON.parse(JSON.stringify(feedbackState?.feedbacks?.data));
      temp.forEach((_feedback) => {
        _feedback.createdBy = _feedback.createdBy?.email;
        _feedback.updatedBy = _feedback.updatedBy?.email;
        _feedback.createdOn = moment(_feedback.createdOn).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        _feedback.updatedOn = moment(_feedback.updatedOn).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        _feedback.feedback = _feedback.feedback;

        _data.push(_feedback);
      });
      console.log("dsadas", _data);

      setTableData(_data);
    }
  }, [feedbackState?.feedbacks?.data]);
  return (
    <Card title="Feedbacks" style={{ margin: 15, maxHeight: "90vh" }}>
      <Table
        columns={columns}
        dataSource={tableData}
        // onChange={onChange}
        loading={feedbackState?.feedbacks?.loading}
        scroll={{
          x: 1300,
          y: 650,
        }}
        style={{ overflow: "auto" }}
      />
    </Card>
  );
}

export default Feedback;
