import React, { useEffect, useState } from "react";
import { getNews, updateNews, addNews } from "../../../redux/newsSlice";
import { useSelector, useDispatch } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Card,
  Image,
  Form,
  Upload,
  Popconfirm,
} from "antd";
import { uploadFiles } from "../../../redux/uploadFilesSlice";
import TextArea from "antd/es/input/TextArea";
import momemt from "moment";
function OncoNews() {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const _data = useSelector((state) => state.onconews.newsList);
  const [editInfo, setEditInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(null);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),

      // width: "10%",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      sorter: (a, b) => a.link.localeCompare(b.link),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "CreatedBy",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "UpdatedBy",
      dataIndex: "updatedBy",
      key: "updatedBy",
      sorter: (a, b) => a.updatedBy && a.updatedBy.localeCompare(b.updatedBy),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "UpdatedOn",
      dataIndex: "updatedOn",
      key: "updatedOn",
      sorter: (a, b) => a.updatedOn && a.updatedOn.localeCompare(b.updatedOn),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      dataIndex: "action",
    },
  ];

  useEffect(() => {
    dispatch(getNews());
  }, []);

  useEffect(() => {
    let data = [];
    if (_data?.data?.length > 0) {
      _data.data.forEach((item, index) => {
        console.log("Item", item);
        data.push({
          key: item.id,
          media: item.media,
          title: item.title,
          link: <a href={item.link}>{item.link}</a>,
          summary: item.summary,
          createdBy: item.createdBy?.email,
          createdOn: momemt(item.createdOn).format("DD-MM-YYYY HH:MM:SS"),
          updatedBy: item.updatedBy?.email,
          updatedOn: momemt(item.updatedOn).format("DD-MM-YYYY HH:MM:SS"),
          action: (
            <Space>
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                  setEditInfo(item);
                }}
                type="primary"
              >
                Edit
              </Button>
              <Popconfirm
                title="Delete the news"
                description="Are you sure to delete this news?"
                onConfirm={() => {
                  dispatch(updateNews({ id: item._id, isActive: false }));
                }}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            </Space>
          ),
        });
      });
      setTableData(data);
    }
  }, [_data.data]);

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const normFile = (e) => {
    console.log("Upload event:", e);
    setUpdateProfile(e.file);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onFinish = (values) => {
    if (Object.keys(editInfo).length === 0) {
      console.log("In add new", values);
      if (updateProfile) {
        dispatch(
          uploadFiles({
            file: updateProfile,
            callback: addCallback,
            defValues: values,
          })
        );
      } else {
        addCallback("", values);
      }
    } else {
      console.log(updateProfile);
      if (updateProfile) {
        dispatch(
          uploadFiles({ file: updateProfile, callback, defValues: values })
        );
      } else {
        callback(editInfo.media, values);
      }
    }
  };
  const addCallback = (picURL, values) => {
    console.log("the values -", values, picURL);
    dispatch(
      addNews({
        title: values.title,
        summary: values.summary,
        link: values.link,
        media: picURL,
      })
    );
    setIsModalVisible(false);
    setEditInfo({});
    setUpdateProfile(null);
  };
  const callback = (uploadedInfo, values) => {
    console.log("the values -", values, uploadedInfo);
    dispatch(
      updateNews({
        id: editInfo._id,
        title: values.title,
        summary: values.summary,
        link: values.link,
        media: uploadedInfo,
      })
    );
    setIsModalVisible(false);
    setEditInfo({});
    setUpdateProfile(null);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditInfo({});
    setUpdateProfile(null);
  };

  return (
    <Card
      title={`Onco News`}
      extra={<Button onClick={() => setIsModalVisible(true)}>Add New</Button>}
      style={{ margin: 15, maxHeight: "90vh" }}
    >
      <Modal
        title={`${Object.keys(editInfo).length > 0 ? "Edit" : "Add"} `}
        open={isModalVisible}
        footer={null}
        // onOk={handleaddNew}
        onCancel={handleCancel}
        width={"90%"}
      >
        <Form
          name="validate_other"
          initialValues={editInfo}
          {...formItemLayout}
          onFinish={onFinish}
          //   style={{
          //     maxWidth: 600,
          //   }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Link"
            name="link"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Summary"
            name="summary"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={10} />
          </Form.Item>
          {Object.keys(editInfo).length !== 0 && (
            <Form.Item label="Current Picture" name="picture">
              <Image
                src={editInfo.media}
                height={200}
                width={200}
                style={{ borderRadius: "50%" }}
              />
            </Form.Item>
          )}

          <Form.Item label="Update Picture">
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={() => {
                  console.log("Upload");
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Update the profile picture of the team member
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {/* <Button htmlType="reset">reset</Button> */}
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Table
        loading={_data.loading}
        columns={columns}
        dataSource={tableData}
        scroll={{
          x: 1000,
          y: 600,
        }}
      />
    </Card>
  );
}

export default OncoNews;
