import React from "react";
import googlebtn from "../../assets/images/google-btn.svg";
import appStoreBtn from "../../assets/images/app-store-btn.svg";
import ctaImg from "../../assets/images/cta-img-new.png";
import fhirImg from "../../assets/images/fhir-img.svg";
import recImg from "../../assets/images/recording-benefit.svg";
import appImg from "../../assets/images/appointment-benefit.svg";
import targetImg from "../../assets/images/target-therapy-benefit.svg";
import onlineForumImg from "../../assets/images/online-forum-benefit.svg";
import journalImg from "../../assets/images/journals-benefit.svg";
import dnaImg from "../../assets/images/dna-benefit.svg";
import newsImg from "../../assets/images/news-benefit.svg";
import symptomImg from "../../assets/images/symptom-monitoring.svg";
import medicalRec from "../../assets/images/medical-records.svg";
import appointmentImg from "../../assets/images/appointment-img.svg";
import pro from "../../assets/images/pro.svg";
import onlineFourm from "../../assets/images/online-forum.svg";
import interOperImg from "../../assets/images/interoperability.svg";
import SymptomRecImg from "../../assets/images/SymptomRec.svg";
import AppointmentImg from "../../assets/images/Appointment.svg";
import TargettherapyImg from "../../assets/images/Targettherapy.svg";
import PatientFourmImg from "../../assets/images/PatientFourm.svg";
import MyJournalsImg from "../../assets/images/MyJournals.svg";
import KnowYourDNAImg from "../../assets/images/KnowYourDNA.svg";
import OncoNewsImg from "../../assets/images/OncoNews.svg";
import InterOperImg from "../../assets/images/Interoper.svg";
function Home() {
  return (
    <>
      <section className="cta-section">
        <div className="container cta-container">
          <div className="cta-grid">
            <div>
              <div>
                <p className="badge">Cancer Treatment Management App</p>
                <h1 className="xxl-text xb-weight cta-title r-b-m">
                  Patient-centric platform for better treatment management
                </h1>
                <p className="r-text r-weight cta-text">
                  All in one app for cancer patients digital needs. Patients can
                  access their data, treatment details all in one place. Access
                  them from any device, from anywhere securely
                </p>
                <div className="cta-btn-container">
                  <img src={googlebtn} alt="google" />
                  <img src={appStoreBtn} alt="appstore" />
                </div>
              </div>
            </div>
            <div>
              <img src={ctaImg} alt="cta" className="cta-img" />
            </div>
          </div>
        </div>
      </section>
      <section className="benefit-section">
        <div className="container">
          <div className="section-header-center">
            <p className="uppercase xs-text b-weight brand-text">
              Features of cureonco app
            </p>
            <h2 className="xl-text xb-weight">
              We integrated features that helps patients and caregivers
            </h2>
          </div>
          <div className="benefit-grid">
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={interOperImg} alt="fhir" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Interoperability</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={SymptomRecImg} alt="sym" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Symptoms Recording</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={AppointmentImg} alt="cta" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Book Appointments</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={TargettherapyImg} alt="cta" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Targeted Cancer Therapy</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={PatientFourmImg} alt="cta" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Patient Forum</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={MyJournalsImg} alt="cta" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">My Journals</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={KnowYourDNAImg} alt="cta" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Know Your DNA</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-img-container">
                <img src={OncoNewsImg} alt="cta" className="benefit-img" />
              </div>
              <p className="r-t-m r-text b-weight">Onco News</p>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-section">
        <div className="container">
          <div className="testimonial-container">
            <div className="testimonial-content">
              <h3 className="lg-text b-weight r-b-m inverted-text">
                Our successful experiences
              </h3>
              <p className="r-text inverted-text ah-op">
                Hear stories of patients who partnered with us on Clinical
                Trails{" "}
              </p>
              <button className="testimonial-btn">Learn more</button>
            </div>
            <div className="testimonal-img-container"></div>
          </div>
        </div>
      </section>

      <section className="why-us-section">
        <div className="container">
          <div className="section-header-center">
            <p className="uppercase xs-text brand-text b-weight">
              Why choose cureonco
            </p>
            <h2 className="xl-text xb-weight center">
              Centralized platform to make treatment process easy
            </h2>
          </div>

          <div className="feature-item-flipped">
            <div className="feature-item-content">
              <p className="badge">Data Integration</p>
              <h3 className="lg-text b-weight r-b-m">
                Data integration provides opportunity for more impactful data
                exchange with providers and patients
              </h3>
              <p className="r-text">
                FHIR Provides one point of access to larger providers ecosystem
                to access the patients data, Easily and safely share data with
                other providers, avoiding delay in care and the redundancy of
                diagnostic testing
              </p>
            </div>
            <div className="feature-img-container">
              <img src={InterOperImg} alt="cta" className="feature-img" />
            </div>
          </div>

          <div className="feature-item">
            <div className="feature-img-container">
              <img src={symptomImg} alt="cta" className="feature-img" />
            </div>
            <div className="feature-item-content">
              <p className="badge">Symptom Monitoring</p>
              <h3 className="lg-text b-weight r-b-m">
                Better insights with Symptoms monitoring intervention{" "}
              </h3>
              <p className="r-text">
                Real-time symptom monitoring intervention would facilitate
                better measurement of treatment-related symptoms resulting in
                more timely interventions and have the potential to reduce
                reduce the ER visit and Prevent adverse side effects.
              </p>
            </div>
          </div>

          <div className="feature-item-flipped">
            <div className="feature-item-content">
              <p className="badge">Medical Records</p>
              <h3 className="lg-text b-weight r-b-m">
                Access medical records with ease
              </h3>
              <p className="r-text">
                Cureonco facilitates patients or caregivers to access data they
                need and provide opportunity to make informed decisions about
                their health, treatment. Enabling patients or caregivers to
                participate in their own care and make possible for second
                opinion.
              </p>
            </div>
            <div className="feature-img-container">
              <img src={medicalRec} alt="cta" className="feature-img" />
            </div>
          </div>

          <div className="feature-item">
            <div className="feature-img-container">
              <img src={appointmentImg} alt="cta" className="feature-img" />
            </div>
            <div className="feature-item-content">
              <p className="badge">Online Appointments</p>
              <h3 className="lg-text b-weight r-b-m">
                Schedule your online appointments with a click of a button
              </h3>
              <p className="r-text">
                Book online appointments with the oncologists to increase the
                efficiency of communication and preventing any errors from
                happening during a procedure or treatment.
              </p>
            </div>
          </div>
          <div className="feature-item-flipped">
            <div className="feature-item-content">
              <p className="badge">Patient Reported Outcome</p>
              <h3 className="lg-text b-weight r-b-m">
                Patient reported outcomes(PRO) for better treatment
              </h3>
              <p className="r-text">
                Every details you tell about your cancer symptoms are recorded
                and stored in a private database to provide you first className
                cutting edge medical treatment.Real-time symptom monitoring
                intervention would facilitate better measurement of
                treatment-related symptoms resulting in more timely
                interventions and have the potential to reduce reduce the ER
                visit and Prevent adverse side effects.
              </p>
            </div>
            <div className="feature-img-container">
              <img src={pro} alt="cta" className="feature-img" />
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-img-container">
              <img src={onlineFourm} alt="cta" className="feature-img" />
            </div>
            <div className="feature-item-content">
              <p className="badge">Online Forum</p>
              <h3 className="lg-text b-weight r-b-m">
                Connect with support groups to keep yourself empowered
              </h3>
              <p className="r-text">
                Online support groups among cancer patients have been shown to
                increase the feeling of empowerment, reduces depression, cancer
                related trauma and stress. Join in your own support group to
                keep yourself motivated.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
