import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import auth from "./authSlice";
import documents from "./documentsSlice";
import users from "./usersSlice";
import askYourDr from "./askYourDrSlice";
import symptoms from "./symptomsSlice";
import targetedTherapy from "./targetedTherapySlice";
import ecTeam from "./ecTeamSlice";
import uploadFiles from "./uploadFilesSlice";
import onconews from "./newsSlice";
import dna from "./knowYourDNASlice";
import fileExplorer from "./fileSlice";
import feedback from "./feedbackSlice";
const reducerStore = configureStore({
  reducer: {
    auth,
    documents,
    users,
    askYourDr,
    symptoms,
    targetedTherapy,
    ecTeam,
    uploadFiles,
    onconews,
    dna,
    fileExplorer,
    feedback,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools extension
});
export default reducerStore;
