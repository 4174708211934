import React from "react";
import Darklogo from "../assets/images/logo-dark.png";
import { appInfo } from "../data/appinfo";

function CFooter() {
  return (
    <>
        <footer>
          <div className="footer-container">
            <div>
              <img src={Darklogo} alt="logo" className="footer-logo" />
            </div>
            <div className="footer-link-grid">
              <div>
                <h5>Company</h5>
                <a href="/">Home</a>
                <a href="/aboutus">About Us</a>
                <a href="/casestudy">Case Studies</a>
                <a href="/contactus">Contact Us</a>
              </div>
              <div>
                <h5>Follow us</h5>
                <a href="https://facebook.com">Facebook</a>
                <a href="https://instagram.com">Instagram</a>
                <a href="https://twitter.com/CureOncoApp">Twitter</a>
              </div>
              <div>
                <h5>Get In Touch</h5>
                <a href={`mailto:${appInfo.contactEmail}`}>
                  {appInfo.contactEmail}
                </a>
                <a href={`tel:${appInfo.phone}`}>{appInfo.phone}</a>
              </div>
            </div>
          </div>
          <div className="footer-copy-container">
            <p className="copy-text">&copy; Cureonco. All rights reserved</p>
          </div>
        </footer>
    </>
  );
}

export default CFooter;
