import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getQuestions,
  updateQuestions,
  deleteCategory,
} from "../../../redux/askYourDrSlice";
import { Collapse, Card, Button, Modal, Popconfirm, Spin } from "antd";
import {
  DeleteOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";

import { Input } from "antd";
const { TextArea } = Input;
function AskDrQuestion() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.askYourDr.questionList);
  const [questionsList, setQuestionsList] = useState([]);
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [newCat, setNewCat] = useState(null);
  const [isNewCatModalVisible, setIsNewCatModalVisible] = useState(false);
  const [editCat, setEditCat] = useState(null);
  useEffect(() => {
    dispatch(getQuestions());
  }, []);
  useEffect(() => {
    if (questions.data.length > 0) {
      setQuestionsList(questions.data);
    }
  }, [questions.data]);
  const changeQuestionValue = (id, value, cat, kind) => {
    let temp = JSON.parse(JSON.stringify(questionsList));
    let index = temp.findIndex((item) => item.title === cat);
    temp[index].data[id].question = value;
    temp[index].data[id].isActive = kind === "delete" ? false : true;
    setQuestionsList(temp);
    setEditCat(cat);
    let _editedQuestion = [];
    _editedQuestion.push({
      question: value,
      isActive: kind === "delete" ? false : true,
      category: cat,
    });
    console.log("editedQuestions", _editedQuestion);
    setEditedQuestions(_editedQuestion);
  };
  const addNewQuestion = (cat) => {
    let temp = JSON.parse(JSON.stringify(questionsList));
    let index = temp.findIndex((item) => item.title === cat);
    temp[index].data.push({
      question: "",
      isActive: true,
      category: cat,
      isNew: true,
    });
    setQuestionsList(temp);
  };
  const saveEditedQuestions = () => {
    dispatch(updateQuestions({ updateData: editedQuestions }));
    setEditCat(null);
  };
  const _deleteCategory = (cat) => {
    dispatch(deleteCategory({ category: cat }));
  };
  const genExtra = (item) => {
    return (
      <>
        {editedQuestions !== null &&
        editedQuestions.length > 0 &&
        item.title === editCat ? (
          <Button
            onClick={() => {
              saveEditedQuestions();
            }}
            icon={<SaveOutlined />}
            size={"medium"}
          ></Button>
        ) : null}
        <Popconfirm
          title="Delete the category"
          description="Are you sure to delete this category?"
          onConfirm={() => {
            _deleteCategory(item.title);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<DeleteOutlined />} size={"medium"}></Button>
        </Popconfirm>
        <Button
          onClick={() => addNewQuestion(item.title)}
          icon={<PlusCircleOutlined />}
          size={"medium"}
        ></Button>
      </>
    );
  };
  const addNewCategory = () => {
    setIsNewCatModalVisible(false);
    setNewCat(null);
    let temp = JSON.parse(JSON.stringify(questionsList));
    temp.push({ title: newCat, data: [] });
    setQuestionsList(temp);
    toast.success("New Category added, Please add questions to save.", {
      position: "bottom-center",
    });
  };
  const handleNewCategoryCancel = () => {
    setIsNewCatModalVisible(false);
    setNewCat(null);
  };

  return (
    <>
      <Modal
        title="Add New Category"
        open={isNewCatModalVisible}
        onOk={addNewCategory}
        onCancel={handleNewCategoryCancel}
      >
        <Input
          placeholder="Name of new category"
          value={newCat}
          onChange={(e) => setNewCat(e.target.value)}
        />
      </Modal>
      <Spin tip="Loading" size="large" spinning={questions.loading}>
        <Card
          style={{
            width: "95%",
            margin: "1%",
            overflow: "auto",
            maxHeight: "90vh",
            height: "87vh",
          }}
          title={"Ask your doctor questions"}
          extra={
            <Button
              onClick={() => setIsNewCatModalVisible(true)}
              icon={<PlusCircleOutlined />}
              size={"medium"}
            >
              New Category
            </Button>
          }
        >
          {questionsList.map((question, index) => (
            <div key={index}>
              <Collapse
                collapsible="header"
                items={[
                  {
                    key: "1",
                    label: <p>{question.title}</p>,
                    extra: genExtra(question),
                    children: (
                      <div>
                        {question.data.length > 0 ? (
                          question.data.map(
                            (item, index) =>
                              item.isActive && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TextArea
                                      placeholder="Enter your question here."
                                      autoSize={{
                                        minRows: 2,
                                        maxRows: 6,
                                      }}
                                      value={item.question}
                                      onChange={(e) =>
                                        changeQuestionValue(
                                          item.id ?? index,
                                          e.target.value,
                                          question.title,
                                          "edit"
                                        )
                                      }
                                      style={{ width: "90%" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Popconfirm
                                        title="Delete the question"
                                        description="Are you sure to delete this question?"
                                        onConfirm={() => {
                                          changeQuestionValue(
                                            item.id ?? index,
                                            item.question,
                                            question.title,
                                            "delete"
                                          );
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <DeleteOutlined
                                          style={{ fontSize: 20 }}
                                        />
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )
                          )
                        ) : (
                          <Button
                            onClick={() => addNewQuestion(question.title)}
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            size={"medium"}
                          >
                            Add New Questions
                          </Button>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
              <br />
            </div>
          ))}
        </Card>
      </Spin>
    </>
  );
}

export default AskDrQuestion;
