import "./App.css";
import "./splide.min.css";
import CHeader from "./components/header";
import CFooter from "./components/footer";
import { router, adminRouter } from "./routes";
import { RouterProvider, BrowserRouter } from "react-router-dom";
import { FloatButton } from "antd";
import { ToastContainer, toast } from "react-toastify";
import base64 from "base-64";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import AHeader from "./layout/admin/AHeader";
import ASider from "./layout/admin/ASider";
import { Layout } from "antd";
const { Header, Content, Footer, Sider } = Layout;
function App() {
  if (
    localStorage.getItem("IS_AUTH") !== "" &&
    localStorage.getItem("IS_AUTH") !== "null" &&
    localStorage.getItem("IS_AUTH") !== null &&
    localStorage.getItem("ROLE") === "admin"
  ) {
    return (
      <>
        <Layout>
          <AHeader />
          <Layout>
            <ASider />
            <Content style={{ height: "92vh" }}>
              <ToastContainer />
              <FloatButton.BackTop />
              <RouterProvider router={adminRouter} />
            </Content>
          </Layout>
          <CFooter />
        </Layout>
      </>
    );
  } else {
    return (
      <>
        {console.log(localStorage.getItem("IS_AUTH"))}
        {(localStorage.getItem("IS_AUTH") !== "" &&
          localStorage.getItem("IS_AUTH") !== "null" &&
          localStorage.getItem("IS_AUTH") !== null) ||
        !window.location.href.includes("login") ? (
          <>
            <CHeader style={{ height: "5%" }} /> 
            {/* <Content style={{ height: "92vh" }}>
          {/* <AHeader /> */}

              <ToastContainer />
              <FloatButton.BackTop />
              <RouterProvider router={router} />
            {/* </Content> */}

            <CFooter />
          </>
        ) : (
          <Login />
        )}
      </>
    );
  }
}

export default App;
