import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import base64 from "base-64";
import { ToastContainer, toast } from "react-toastify";

export const getTeamList = createAsyncThunk(
  "ecTeam/teamList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/ec`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting team list please Try Again Later"
      );
    }
  }
);

export const updateTeamInfo = createAsyncThunk(
  "ecTeam/updateTeam",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(`/ec`, data);
      dispatch(getTeamList());
      toast.success("Updated the information successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in updating List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while updating team list please Try Again Later"
      );
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "ecTeam/addTeam",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/ec`, data);
      toast.success("Updated the information successfully.", {
        position: "bottom-center",
      });
      dispatch(getTeamList());
      return res.data.data;
    } catch (err) {
      toast.error("Error in creating a new member", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while adding team please Try Again Later"
      );
    }
  }
);
const initialState = {
  teamList: {
    loading: false,
    data: [],
  },
};
export const ecSlice = createSlice({
  name: "ecTeam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeamList.fulfilled, (state, action) => {
        state.teamList.data = action.payload;
        state.teamList.loading = false;
      })
      .addCase(getTeamList.pending, (state, action) => {
        state.teamList.loading = true;
      })
      .addCase(getTeamList.rejected, (state, action) => {
        state.teamList.loading = false;
      });
  },
});

export const { toggleLoginModal } = ecSlice.actions;
export default ecSlice.reducer;
