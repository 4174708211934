import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../redux/newsSlice";
import newsbg from "../../assets/images/news-bg.png";
import moment from "moment";
import { Image } from "antd";
function News() {
  const dispatch = useDispatch();
  const _data = useSelector((state) => state.onconews.newsList);
  useEffect(() => {
    dispatch(getNews());
  }, []);
  return (
    <>
      <section className="page-header-section">
        <div className="container page-header-container-divided">
          <div className="page-header-content">
            {/* <p className="page-header-badge">Onco News</p> */}
            <h1 className="xl-text b-weight inverted-text r-b-m">
              Keeping you updated on news about Oncology
            </h1>
            <p className="r-text inverted-text ah-op">
              Onco news will help you know recent news about oncology ranging
              from new cure, treatments, survivor&apos;s experience, tips to
              follow and much more from a single platform
            </p>
          </div>
          <div className="page-header-img-container">
            <img src={newsbg} alt="news" className="about-bg" />
          </div>
        </div>
      </section>

      {/* <section className="recent-news-section">
        <div className="container">
          <div className="section-header xxl-b-m">
            <h2 className="lg-text xb-weight">Recent News</h2>
          </div>
          <div className="recent-news-grid">
            <div className="recent-news-item">
              <div className="recent-news-img"></div>
              <div className="recent-news-item-content">
                <p className="r-text m-weight">
                  Vedolizumab Plus Standard Prophylaxis Reduces Rate of Lower GI
                  aGVHD drastically
                </p>
                <p className="h-op sm-text m-weight">Jason Harris</p>
                <p className="h-op xs-text">February 20, 2023</p>
              </div>
            </div>
            <div className="recent-news-item">
              <div className="recent-news-img"></div>
              <div className="recent-news-item-content">
                <p className="r-text m-weight">
                  Vedolizumab Plus Standard Prophylaxis Reduces Rate of Lower GI
                  aGVHD drastically
                </p>
                <p className="h-op sm-text m-weight">Jason Harris</p>
                <p className="h-op xs-text">February 20, 2023</p>
              </div>
            </div>
            <div className="recent-news-item">
              <div className="recent-news-img"></div>
              <div className="recent-news-item-content">
                <p className="r-text m-weight">
                  Vedolizumab Plus Standard Prophylaxis Reduces Rate of Lower GI
                  aGVHD drastically
                </p>
                <p className="h-op sm-text m-weight">Jason Harris</p>
                <p className="h-op xs-text">February 20, 2023</p>
              </div>
            </div>
            <div className="recent-news-item">
              <div className="recent-news-img"></div>
              <div className="recent-news-item-content">
                <p className="r-text m-weight">
                  Vedolizumab Plus Standard Prophylaxis Reduces Rate of Lower GI
                  aGVHD drastically
                </p>
                <p className="h-op sm-text m-weight">Jason Harris</p>
                <p className="h-op xs-text">February 20, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="all-news-section">
        <div className="sm-container">
          <div className="section-header m-b-m">
            <h2 className="lg-text xb-weight">All News</h2>
          </div>
          <div className="recent-news-grid">
            {_data.data.map((item, index) => (
              <div className="recent-news-item">
                <div className="recent-news-img">
                  <Image
                    src={item.media}
                    alt="news"
                    height={"100%"}
                    width={"100%"}
                    className="recent-news-img"
                  />
                </div>
                <div className="recent-news-item-content">
                  <p className="r-text m-weight">
                    <a href={item.link} target="_blank">
                      {item.title}
                    </a>
                  </p>

                  <p className="h-op xs-text">
                    {moment(item.createdOn).format("YYYY-MM-DD HH:MM:SS")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
