import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import { ToastContainer, toast } from "react-toastify";

export const getQuestions = createAsyncThunk(
  "askyourdr/questionList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/users/getaskdrquestions`);
      return res.data.data;
    } catch (err) {
      return rejectWithValue(
        "Error while getting questions list please Try Again Later"
      );
    }
  }
);
export const deleteCategory = createAsyncThunk(
  "askyourdr/deleteCategory",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.delete(`/users/getaskdrquestions`, {
        data,
      });
      dispatch(getQuestions());
      toast.success("Deleted the category successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in deleting category", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while deleting category please Try Again Later"
      );
    }
  }
);
export const updateQuestions = createAsyncThunk(
  "askyourdr/updatequestionList",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(`/users/getaskdrquestions`, data);
      toast.success("Updated the question successfully.", {
        position: "bottom-center",
      });
      dispatch(getQuestions());
      return res.data.data;
    } catch (err) {
      toast.error("Error in updating question", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while updating questions list please Try Again Later"
      );
    }
  }
);

const initialState = {
  questionList: {
    loading: false,
    data: [],
  },
};

export const askyourdrSlice = createSlice({
  name: "askyourdr",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questionList.data = action.payload;
        state.questionList.loading = false;
      })
      .addCase(getQuestions.pending, (state, action) => {
        state.questionList.loading = true;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.questionList.loading = false;
      });
  },
});

export const {} = askyourdrSlice.actions;
export default askyourdrSlice.reducer;
