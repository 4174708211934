import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import menu from "../assets/images/menu.svg";
import { Avatar } from "antd";

function CHeader() {
  const [isMobileMenuShow, setMobileMenuShow] = useState(false);
  const [open, setOpen] = useState(false);
  const toogleMenu = () => {
    setMobileMenuShow(!isMobileMenuShow);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <nav className="mainNav">
        <div className="nav-container">
          <div className="nav-content">
            <a href="/home">
              <img src={logo} alt="logo" className="nav-logo" />
            </a>
            <div className="nav-link-container">
              <a
                className={
                  window.location.href.includes("home") ? "nav-active-item" : ""
                }
                href="/home"
              >
                Home
              </a>
              <a
                className={
                  window.location.href.includes("aboutus")
                    ? "nav-active-item"
                    : ""
                }
                href="/aboutus"
              >
                About us
              </a>
              <a
                className={
                  window.location.href.includes("casestudy")
                    ? "nav-active-item"
                    : ""
                }
                href="/casestudy"
              >
                Case Studies
              </a>
              <a
                className={
                  window.location.href.includes("onconews")
                    ? "nav-active-item"
                    : ""
                }
                href="/onconews"
              >
                Onco News
              </a>
              <a
                className={
                  window.location.href.includes("contactus")
                    ? "nav-active-item"
                    : ""
                }
                href="/contactus"
              >
                Contact us
              </a>
            </div>
            {localStorage.getItem("IS_AUTH") === "" ||
            localStorage.getItem("IS_AUTH") === "null" ||
            localStorage.getItem("IS_AUTH") === null ? (
              <div className="nav-btn-container">
                <a href="/login" alt="logo" className="nav-login-btn">
                  Login
                </a>
                {/* <a
                  href="https://app.cureonco.com/signup"
                  alt="logo"
                  className="nav-signup-btn"
                >
                  Signup
                </a> */}
              </div>
            ) : (
              <>
                <div className="nav-btn-container">
                  <Avatar
                    onClick={() => {
                      window.localStorage.clear();
                      window.location.href = "/";
                    }}
                  >
                    {JSON.parse(localStorage.getItem("USER_INFO"))?.user?.firstName[0] ?? '-'}
                  </Avatar>{" "}
                  &nbsp; &nbsp;
                  <a
                    href="/"
                    alt="logo"
                    className="nav-signup-btn"
                    onClick={() => window.localStorage.clear()}
                  >
                    Logout
                  </a>
                </div>
              </>
            )}
          </div>
          <div className="mob-nav-content">
            <div>
              <a href="/">
                <img src={logo} alt="logo" className="mob-nav-logo" />
              </a>
            </div>
            <span onClick={toogleMenu}>
              <img src={menu} alt="menu" className="nav-menu-icon" />
            </span>
          </div>
        </div>
        <div
          id="mobile-nav"
          className={`mob-nav-menu-container  ${
            isMobileMenuShow ? "mob-nav-active" : ""
          }`}
        >
          <a href="/aboutus">About us</a>
          <a href="/casestudy">Case Studies</a>
          <a href="/onconews">Onco News</a>
          <a href="/contactus">Contact us</a>

          {localStorage.getItem("IS_AUTH") === "" ||
          localStorage.getItem("IS_AUTH") === "null" ||
          localStorage.getItem("IS_AUTH") === null ? (
            <div className="nav-btn-container">
              <a href="/login" alt="logo" className="nav-login-btn">
                Login
              </a>
              {/* <a
                href="https://app.cureonco.com/signup"
                className="nav-signup-btn"
              >
                Signup
              </a> */}
            </div>
          ) : (
            <>
              <div className="nav-btn-container" style={{zIndex:999}}>
                <Avatar
                  onClick={() => {
                    window.localStorage.clear();
                    window.location.href = "/";
                  }}
                >
                  {JSON.parse(localStorage.getItem("USER_INFO"))?.user?.firstName[0] ?? '-'}
                </Avatar>{" "}
                &nbsp; &nbsp;
                <a
                  href="/"
                  alt="logo"
                  style={{width:'200px'}}
                  className="nav-signup-btn"
                  onClick={() => window.localStorage.clear()}
                >
                  Logout
                </a>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
}

export default CHeader;
