import {
  ChonkyActions,
  ChonkyFileActionData,
  FileArray,
  FileBrowserProps,
  FileData,
  FileHelper,
  FullFileBrowser,
  defineFileAction,
} from "chonky";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getFolderList,
  getFilesList,
  renameFile,
  moveFile,
  newFolder,
  deleteFileFolder,
  getFileAcessURL,
  copyFile,
} from "../../redux/fileSlice";
import { Drawer, Spin } from "antd";
import "./index.css";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import UploadFiles from "../Files/Uploadfiles";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
const initialTreeData = {
  rootFolderId: "CureoncoDoc",
  fileMap: {
    CureoncoDoc: {
      id: "CureoncoDoc",
      name: "Documents",
      isDir: true,
      childrenIds: [],
      childrenCount: 0,
    },
  },
};
export const FileBrowser = React.memo((props) => {
  useEffect(() => {
    dispatch(getFilesList());
    dispatch(getFolderList());
  }, []);
  const fileBrowserRef = useRef(null);

  // State to manage active folder (used for history/breadcrumbs)
  const [activeFolder, setActiveFolder] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [placement, setPlacement] = useState("right");

  const _expdata = useSelector((state) => state.fileExplorer);

  // State to hold your tree data
  const [treeData, settreeData] = useState(initialTreeData);

  useEffect(() => {
    console.log("asdasd", _expdata.files?.data, _expdata.folder?.data);

    // Don't process if files or folders data is missing
    if (!_expdata.files?.data || !_expdata.folder?.data) return;
    let files = _expdata.files?.data;
    let folders = _expdata.folder?.data;
    let newFormat = {
      rootFolderId: "CureoncoDoc",
      fileMap: {
        CureoncoDoc: {
          id: "CureoncoDoc",
          name: "Documents",
          isDir: true,
          color: "#124b81",
          childrenIds: folders.map((x) => x._id),
          childrenCount: folders.map((x) => x._id).length,
        },
      },
    };

    const generateNewFormat = () => {
      let newmap = newFormat.fileMap;
      let newOne = { ...newmap };
      folders.forEach((x) => {
        let childrens = getChildren(x._id);
        newOne = {
          ...newOne,
          [x._id]: {
            id: x._id,
            name: x.folderName,
            isDir: true,
            color: "#124b81",
            modDate: x.createdOn,
            childrenCount: childrens.length,
            childrenIds: childrens.map((x) => x._id),
            parentId: "CureoncoDoc",
          },
        };
        childrens.forEach((y) => {
          newOne = {
            ...newOne,
            [y._id]: {
              id: y._id,
              name: y.fileName,
              modDate: y.createdOn,
              parentId: x._id,
            },
          };
        });
      });
      newFormat.fileMap = {
        CureoncoDoc: {
          id: "CureoncoDoc",
          name: "Documents",
          color: "#124b81",
          isDir: true,
          childrenIds: folders.map((x) => x._id),
          childrenCount: folders.map((x) => x._id).length,
        },
        ...newOne,
      };
      return newFormat;
    };
    const getChildren = (id) => {
      const children = files.filter((x) => x.category === id);
      console.log("childred", children);
      return children;
    };
    let withFolders = generateNewFormat();
    console.log("Im asd", withFolders);
    settreeData(withFolders);
  }, [_expdata.files, _expdata.folder]);
  // Update breadcrumbs and currentFolderId when the location changes

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const _rename = (name, data) => {
    console.log(name, data);
    dispatch(
      renameFile({
        name,
        id: data.state.selectedFilesForAction[0].id,
        kind: data.state.selectedFilesForAction[0].isDir ? "folder" : "file",
      })
    );
  };
  const _moveFiles = (files, source, destination) => {
    console.log("ssdsd", files, source, destination);
    let data = {
      filesToMove: files.map((x) => x.id),
      destFolder: destination.id,
    };
    dispatch(moveFile(data));
  };
  const _newFolder = (data) => {
    dispatch(newFolder([{ folderName: data }]));
  };
  const _deleteFileFolder = (data) => {
    console.log(data);
    let _data = {
      delType: data[0].isDir ? "folder" : "file",
      id: data.map((x) => x.id),
    };
    dispatch(deleteFileFolder(_data));
  };
  const _uploadFiles = () => {
    setOpen(true);
  };
  const rename = [
    defineFileAction({
      id: "rename-file",
      name: "Rename",
      button: {
        name: "Rename",
        toolbar: false,
        contextMenu: true,
      },
      action: async (file) => {
        console.log("In rename");
        const newName = prompt("Enter the new name for the file:", file.name);

        if (newName) {
          // ... Logic to send rename request to your server (see Step 3)
        }
      },
    }),
  ];
  const uploadFiles = [
    defineFileAction({
      id: "upload_new_files",
      hotkeys: ["ctrl+u"],
      button: {
        name: "Upload Files",
        toolbar: true,
        contextMenu: false,
      },
      fileFilter: (file, folderChain) => {
        return FileHelper.isDirectory(file) && folderChain.length > 1;
      },
    }),
  ];
  const copyFiles = defineFileAction({
    id: "copy-files",
    name: "Copy",
    hotkeys: ["ctrl+c"],
    button: {
      name: "Copy",
      toolbar: false,
      contextMenu: true,
    },
  });

  const pasteFiles = defineFileAction({
    id: "paste-files",
    name: "Paste",
    hotkeys: ["ctrl+v"],
    button: {
      name: "Paste",
      toolbar: false,
      contextMenu: true,
    },
    // Enable only when there are files in the clipboard:
    fileFilter: (file, folderChain) => folderChain.length > 1,
  });
  // Hook to set up file map, deleteFiles, moveFiles, etc.
  // Updated to use your dynamic tree data
  const useCustomFileMap = () => {
    // Ensure treeData exists before proceeding
    const { baseFileMap, rootFolderId } = useMemo(() => {
      if (!treeData) return {}; // Or return a default/loading state
      return {
        baseFileMap: treeData.fileMap,
        rootFolderId: treeData.rootFolderId,
      };
    }, [treeData]);

    // Setup the React state for our file map and the current folder.

    // Setup the React state for our file map and the current folder.
    const [fileMap, setFileMap] = useState({}); // Initialize as empty
    // const [fileMap, setFileMap] = useState(baseFileMap);
    useEffect(() => {
      if (baseFileMap) {
        setFileMap(baseFileMap);
      }
    }, [baseFileMap]);
    // if(baseFileMap && fileMap === undefined){
    //     setFileMap(baseFileMap)
    // }
    console.log("the data is now", fileMap);

    const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);

    // Setup the function used to reset our file map to its initial value. Note that
    // here and below we will always use `useCallback` hook for our functions - this is
    // a crucial React performance optimization, read more about it here:
    // https://reactjs.org/docs/hooks-reference.html#usecallback
    const resetFileMap = useCallback(() => {
      setFileMap(baseFileMap);
      setCurrentFolderId(rootFolderId);
    }, [baseFileMap, rootFolderId]);

    // Setup logic to listen to changes in current folder ID without having to update
    // `useCallback` hooks. Read more about it here:
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
    const currentFolderIdRef = useRef(currentFolderId);
    useEffect(() => {
      currentFolderIdRef.current = currentFolderId;
    }, [currentFolderId]);

    // Function that will be called when user deletes files either using the toolbar
    // button or `Delete` key.
    const deleteFiles = useCallback((files) => {
      // We use the so-called "functional update" to set the new file map. This
      // lets us access the current file map value without having to track it
      // explicitly. Read more about it here:
      // https://reactjs.org/docs/hooks-reference.html#functional-updates
      setFileMap((currentFileMap) => {
        // Create a copy of the file map to make sure we don't mutate it.
        const newFileMap = { ...currentFileMap };

        files.forEach((file) => {
          // Delete file from the file map.
          delete newFileMap[file.id];

          // Update the parent folder to make sure it doesn't try to load the
          // file we just deleted.
          if (file.parentId) {
            const parent = newFileMap[file.parentId];
            const newChildrenIds = parent.childrenIds.filter(
              (id) => id !== file.id
            );
            newFileMap[file.parentId] = {
              ...parent,
              childrenIds: newChildrenIds,
              childrenCount: newChildrenIds.length,
            };
          }
        });

        return newFileMap;
      });
    }, []);

    // Function that will be called when files are moved from one folder to another
    // using drag & drop.
    const moveFiles = useCallback((files, source, destination) => {
      setFileMap((currentFileMap) => {
        const newFileMap = { ...currentFileMap };
        const moveFileIds = new Set(files.map((f) => f.id));

        // Delete files from their source folder.
        const newSourceChildrenIds = source.childrenIds.filter(
          (id) => !moveFileIds.has(id)
        );
        newFileMap[source.id] = {
          ...source,
          childrenIds: newSourceChildrenIds,
          childrenCount: newSourceChildrenIds.length,
        };

        // Add the files to their destination folder.
        const newDestinationChildrenIds = [
          ...destination.childrenIds,
          ...files.map((f) => f.id),
        ];
        newFileMap[destination.id] = {
          ...destination,
          childrenIds: newDestinationChildrenIds,
          childrenCount: newDestinationChildrenIds.length,
        };

        // Finally, update the parent folder ID on the files from source folder
        // ID to the destination folder ID.
        files.forEach((file) => {
          newFileMap[file.id] = {
            ...file,
            parentId: destination.id,
          };
        });

        return newFileMap;
      });
    }, []);

    // Function that will be called when user creates a new folder using the toolbar
    // button. That that we use incremental integer IDs for new folder, but this is
    // not a good practice in production! Instead, you should use something like UUIDs
    // or MD5 hashes for file paths.
    const idCounter = useRef(0);
    const createFolder = useCallback((folderName) => {
      setFileMap((currentFileMap) => {
        const newFileMap = { ...currentFileMap };

        // Create the new folder
        const newFolderId = `new-folder-${idCounter.current++}`;
        newFileMap[newFolderId] = {
          id: newFolderId,
          name: folderName,
          isDir: true,
          modDate: new Date(),
          parentId: currentFolderIdRef.current,
          childrenIds: [],
          childrenCount: 0,
        };

        // Update parent folder to reference the new folder.
        const parent = newFileMap[currentFolderIdRef.current];
        newFileMap[currentFolderIdRef.current] = {
          ...parent,
          childrenIds: [...parent.childrenIds, newFolderId],
        };

        return newFileMap;
      });
    }, []);

    return {
      fileMap,
      currentFolderId,
      setCurrentFolderId,
      resetFileMap,
      deleteFiles,
      moveFiles,
      createFolder,
    };
  };
  const getFileURL = (id) => {
    dispatch(getFileAcessURL({ id, callback: fileURL }));
  };
  const fileURL = (url) => {
    window.open(url.fileAccessURL, "_blank");
  };
  const useFiles = (fileMap, currentFolderId) => {
    return useMemo(() => {
      const currentFolder = fileMap?.[currentFolderId];
      const childrenIds = currentFolder?.childrenIds;
      const files = childrenIds && childrenIds.map((fileId) => fileMap[fileId]);
      return files;
    }, [currentFolderId, fileMap]);
  };

  const useFolderChain = (fileMap, currentFolderId) => {
    return useMemo(() => {
      const currentFolder = fileMap?.[currentFolderId];

      const folderChain = [currentFolder];

      let parentId = currentFolder?.parentId;
      while (parentId) {
        const parentFile = fileMap?.[parentId];
        if (parentFile) {
          folderChain.unshift(parentFile);
          parentId = parentFile.parentId;
        } else {
          break;
        }
      }

      return folderChain;
    }, [currentFolderId, fileMap]);
  };

  const useFileActionHandler = (
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder,
    _rename,
    _moveFiles,
    _newFolder,
    _deleteFileFolder,
    _uploadFiles,
    folderChain
  ) => {
    const [clipboard, setClipboard] = useState(null); // State to store copied files
    return useCallback(
      (data) => {
        if (data.id === "copy-files") {
          toast.success(
            "Copied file to clip board, Press ctrl+v to paste in any folder.",
            {
              position: "bottom-center",
            }
          );
          setClipboard(data.state.selectedFilesForAction); // Store the copied files
        } else if (data.id === "paste-files") {
          console.log("the data is", folderChain);
          if (clipboard && folderChain.length > 1) {
            // Check if clipboard has files and we're not in root
            const destinationFolderId = folderChain[folderChain.length - 1].id; // Get current folder ID
            console.log("the clipboard", clipboard);
            let ids = clipboard.map((x) => x.id);
            dispatch(
              copyFile({ filesToCopy: ids, destFolder: destinationFolderId })
            );
            // Make a request to your backend to copy the files from `clipboard` to `destinationFolderId`
            console.log(clipboard);
          }
        }
        if (data.id === ChonkyActions.OpenFiles.id) {
          const { targetFile, files } = data.payload;
          const fileToOpen = targetFile ?? files[0];
          if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
            setCurrentFolderId(fileToOpen.id);
            setActiveFolder(targetFile.id);
            navigate(`/filebrowser/${targetFile.id}`);
            // Add to history
            return;
          } else if (fileToOpen && !FileHelper.isDirectory(fileToOpen)) {
            console.log("File to open", fileToOpen);
            getFileURL(fileToOpen.id);
          }
        } else if (data.id === ChonkyActions.DeleteFiles.id) {
          deleteFiles(data.state.selectedFilesForAction);
          _deleteFileFolder(data.state.selectedFilesForAction);
        } else if (data.id === ChonkyActions.MoveFiles.id) {
          moveFiles(
            data.payload.files,
            data.payload.source,
            data.payload.destination
          );
          _moveFiles(
            data.payload.files,
            data.payload.source,
            data.payload.destination
          );
        } else if (data.id === ChonkyActions.CreateFolder.id) {
          const folderName = prompt("Provide the name for your new folder:");
          if (folderName) {
            createFolder(folderName);
            _newFolder(folderName);
          }
        } else if (data.id === "rename-file") {
          const folderName = prompt("Provide new name to folder:");
          if (folderName) _rename(folderName, data);
        } else if (data.id === "upload_new_files") {
          if (folderChain.length > 1) {
            _uploadFiles();
          } else {
            console.log("Uploads are only allowed within folders.");
          }
        }
        // showActionNotification(data);
      },
      [
        createFolder,
        deleteFiles,
        moveFiles,
        setCurrentFolderId,
        _rename,
        _moveFiles,
        _newFolder,
        _deleteFileFolder,
        _uploadFiles,
      ]
    );
  };

  const {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    resetFileMap,
    deleteFiles,
    moveFiles,
    createFolder,
  } = useCustomFileMap();
  const files = useFiles(fileMap, currentFolderId);
  const folderChain = useFolderChain(fileMap, currentFolderId);
  const handleFileAction = useFileActionHandler(
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder,
    _rename,
    _moveFiles,
    _newFolder,
    _deleteFileFolder,
    _uploadFiles,
    folderChain
  );
  const fileActions = useMemo(() => {
    const defaultActions = [ChonkyActions.DeleteFiles];
    if (folderChain.length <= 1) {
      defaultActions.push(ChonkyActions.CreateFolder);
    }
    // Check if we are NOT in the root folder (folderChain.length > 1)
    if (folderChain.length > 1) {
      // Add the upload action only when not in root
      defaultActions.push(...uploadFiles);
    }

    // Always include rename action
    defaultActions.push(...rename);
    defaultActions.push(copyFiles);
    defaultActions.push(pasteFiles);

    return defaultActions;
  }, [folderChain]);
  const thumbnailGenerator = useCallback(
    (file) =>
      file.thumbnailUrl ? `https://chonky.io${file.thumbnailUrl}` : null,
    []
  );
  const handleFiluploadCallback = async () => {
    // await dispatch(getFilesList());
    // await dispatch(getFolderList());
    window.location.reload();
  };
  const breadcrumbs = useMemo(() => {
    // If folderChain is empty, create a default breadcrumb for the root folder
    if (!folderChain || folderChain.length === 0) {
      return [
        {
          id: "CureoncoDoc",
          name: "Documents",
          onClick: () => navigate("/filebrowser/CureoncoDoc"),
        },
      ];
    }

    // If folderChain has items, create breadcrumbs as before
    return folderChain.map((folder) => ({
      id: folder?.id,
      name: folder?.name,
      onClick: () => {
        setCurrentFolderId(folder?.id);
        setActiveFolder(folder?.id);
        navigate(`/filebrowser/${folder?.id}`);
      },
    }));
  }, [folderChain, setCurrentFolderId, navigate]);
  // Your custom folder component
  function MyCustomFolder({ file }) {
    console.log("THE FILE", file);
    return (
      <div style={{ color: "#009688" }}>
        {" "}
        /* Green for folders */ // eslint-disable-next-line react/jsx-no-undef
        <ChonkyIconFA icon={ChonkyIconFA.Folder} /> {file.name}
      </div>
    );
  }

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const folderId =
      pathSegments.length > 1 ? pathSegments[pathSegments.length - 1] : null;
    if (folderId && fileMap[folderId]) {
      setCurrentFolderId(folderId);
      setActiveFolder(folderId);
    } else {
      setCurrentFolderId("CureoncoDoc"); // Default to root folder if folderId is invalid
    }
  }, [location, fileMap, setCurrentFolderId]);
  return (
    <>
      <section
        style={{
          background: "white !important",
          height: "90vh",
          marginTop: "15vh",
        }}
      >
        {_expdata.files.loading || _expdata.folder.loading ? (
          <Spin
            style={{ marginTop: "50vh", marginLeft: "50%" }}
            size="large"
            tip="Loading"
          ></Spin>
        ) : (
          treeData && (
            <FullFileBrowser
              ref={fileBrowserRef}
              style={{
                marginTop: "15vh !important",
                fontSize: "14px", // Change the font size of file/folder names
              }}
              fileComponent={MyCustomFolder}
              files={files}
              folderChain={folderChain}
              fileActions={fileActions}
              onFileAction={handleFileAction}
              thumbnailGenerator={thumbnailGenerator}
              breadcrumbs={breadcrumbs}
              {...props}
            />
          )
        )}
        <Drawer
          title="Upload Files"
          placement={placement}
          closable={true}
          onClose={onClose}
          open={open}
          key={placement}
          footer={null}
        >
          <UploadFiles
            folderId={currentFolderId}
            handleCallback={() => {
              setOpen(false);
              handleFiluploadCallback();
            }}
          />
        </Drawer>
      </section>
    </>
  );
});
