import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import base64 from "base-64";
import { ToastContainer, toast } from "react-toastify";


export const uploadFiles = createAsyncThunk(
  "upload/files",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.keys(data.file).map((item) => {
        formData.append("file", data.file[item]);
      });

      const res = await axiosInstance.post(`/ec/upload`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      data.callback(res.data.data[0]?.cloudStoragePublicUrl, data.defValues);
      toast.success("Uploaded successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      console.log("the error -", err);
      return rejectWithValue(
        "Error while getting team list please Try Again Later"
      );
    }
  }
);

const initialState = {
  upload: {
    loading: false,
    data: [],
  },
};
export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.upload.data = action.payload;
        state.upload.loading = false;
      })
      .addCase(uploadFiles.pending, (state, action) => {
        state.upload.loading = true;
      })
      .addCase(uploadFiles.rejected, (state, action) => {
        state.upload.loading = false;
      });
  },
});

export const { toggleLoginModal } = uploadSlice.actions;
export default uploadSlice.reducer;
