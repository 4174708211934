import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import { act } from "react-dom/test-utils";
import { ToastContainer, toast } from "react-toastify";

export const getFolderList = createAsyncThunk(
  "docs/getFolderList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get("/files/folderlist");
      return res.data;
    } catch (err) {
      return rejectWithValue(
        "Error while getting folder list, please Try Again Later"
      );
    }
  }
);
export const getFilesList = createAsyncThunk(
  "docs/getFilesList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/users/getfiles/${data.folderId}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(
        "Error while getting files list, please Try Again Later"
      );
    }
  }
);
export const uploadFile = createAsyncThunk(
  "docs/uploadFiles",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      console.log("THE FILE NORMAL", data.file);
      Object.keys(data.file).map((item) => {
        formData.append("file", data.file[item]);
      });

      formData.append("category", data.category);
      const res = await axiosInstance.post("/users/uploadFiles", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      return { data: res.data, callback: data.callback };
    } catch (err) {
      console.log(err);
      toast.success("error in uploading files.", {
        position: "bottom-center",
      });
  
      return rejectWithValue(
        "Error while getting folder list, please Try Again Later"
      );
    }
  }
);

const initialState = {
  isLoading: false,
  folderList: {
    isLoading: false,
    data: [],
  },
  files: {
    folderId: "",
    data: [],
    isLoading: false,
  },
  uploadedFiles: {
    isLoading: false,
    data: [],
  },
};
export const documentsSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFolderList.fulfilled, (state, action) => {
        state.folderList.data = action.payload.data;
        state.folderList.isLoading = false;
      })
      .addCase(getFolderList.pending, (state, action) => {
        state.folderList.isLoading = true;
      })
      .addCase(getFolderList.rejected, (state, action) => {
        state.folderList.isLoading = false;
      })

      .addCase(uploadFile.fulfilled, (state, action) => {
        state.uploadedFiles.data = action.payload.data;
        state.isLoading = false;
        action.payload.callback(action.payload.data);
      })
      .addCase(uploadFile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getFilesList.fulfilled, (state, action) => {
        state.files.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getFilesList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFilesList.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {} = documentsSlice.actions;
export default documentsSlice.reducer;
