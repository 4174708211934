import React from "react";
import { Card } from "antd";
function Landing() {
  return (
    <Card style={{ margin: 10, height: "97%" }}>
      <div>
        <h1>Welcome to CureOnco Admin Panel</h1>
        <br />
        <p>
          This is the admin panel of CureOnco. You can manage users, ask doctor
          questions, manage symptoms, targeted therapy, teams, OncoNews, and
          Know Your DNA.
        </p>
      </div>
    </Card>
  );
}

export default Landing;
