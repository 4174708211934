import React, { useEffect, useState } from "react";
import { getList } from "../../../redux/targetedTherapySlice";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Input, Form, Card, Popconfirm } from "antd";
import { Table } from "antd";
import {
  updateTargetedTherapy,
  addTargetedTherapy,
  deleteTargetedTherapy,
} from "../../../redux/targetedTherapySlice";
import moment from "moment";
function TargetedTherapy() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.targetedTherapy);
  const [editInfo, setEditInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const columns = [
    {
      title: "Target",
      dataIndex: "target",
      sorter: (a, b) => a.target.localeCompare(b.target),
    },
    {
      title: "Drug",
      dataIndex: "drug",
      sorter: (a, b) => a.drug.localeCompare(b.drug),
    },
    {
      title: "Cancer Type",
      dataIndex: "cancerType",
      sorter: (a, b) => a.cancerType.localeCompare(b.cancerType),
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Updated On",
      dataIndex: "updatedOn",
      sorter: (a, b) => a.updatedOn.localeCompare(b.updatedOn),
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy && a.updatedBy.localeCompare(b.updatedBy),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      dataIndex: "action",
    },
  ];
  useEffect(() => {
    dispatch(getList());
  }, []);
  useEffect(() => {
    if (data?.targetedTherapy?.data.length > 0) {
      let temp = JSON.parse(JSON.stringify(data?.targetedTherapy?.data));
      temp.forEach((item) => {
        item.createdBy = item.createdBy?.email;
        item.updatedBy = item.updatedBy?.email;
        item.createdOn = moment(item.createdOn).format("YYYY-MM-DD HH:mm:ss");
        item.updatedOn = moment(item.updatedOn).format("YYYY-MM-DD HH:mm:ss");

        item.action = (
          <>
            <Button
              onClick={() => handleEdit(item)}
              shape="circle"
              icon={<EditOutlined />}
            />
            <Popconfirm
              title="Delete the targeted therapy?"
              description="Are you sure to delete this targeted therapy?"
              onConfirm={() => handleDelete(item)}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{ marginLeft: 2 }}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </>
        );
      });

      setTableData(temp);
    }
  }, [data.targetedTherapy]);
  const handleEdit = (therapy) => {
    setEditInfo(therapy);
    console.log("therapy", therapy);
    setIsModalVisible(true);
  };
  const handleDelete = (therapy) => {
    dispatch(deleteTargetedTherapy(therapy._id));
  };
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  const handleaddNew = () => {
    setIsModalVisible(false);
    setEditInfo({});
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditInfo({});
  };
  const handleFormSubmission = (data) => {
    if (Object.keys(editInfo).length > 0) {
      let id = editInfo._id;
      dispatch(updateTargetedTherapy({ ...data, id }));
    } else {
      dispatch(addTargetedTherapy(data));
    }
    setIsModalVisible(false);
    setEditInfo({});
  };
  return (
    <Card
      title="Targetted Therapy List"
      extra={<Button onClick={() => {setIsModalVisible(true)}}>Add New</Button>}
      style={{ margin: 15, maxHeight: "90vh" }}
     
    >
      <Modal
        title={`${Object.keys(editInfo).length > 0 ? "Edit" : "Add"} `}
        open={isModalVisible}
        onOk={handleaddNew}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="wrap"
          labelCol={{
            flex: "110px",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          style={{
            maxWidth: 600,
          }}
          initialValues={editInfo}
          onFinish={handleFormSubmission}
        >
          <Form.Item
            label="Cancer Type"
            name="cancerType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Drug"
            name="drug"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Target"
            name="target"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={data.targetedTherapy.loading}
        scroll={{
          x: 1300,
          y: 650,
        }}
        style={{ overflow: "auto" }}
      />
    </Card>
  );
}

export default TargetedTherapy;
