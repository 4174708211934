import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFilesList } from "../../redux/documentsSlice";
import { Route, useNavigate, useParams } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";
import { Alert, Flex, Spin, Card } from "antd";

function Files() {
  let param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documentState = useSelector((state) => state.documents);

  React.useEffect(() => {
    dispatch(getFilesList({ folderId: param.folderId }));
  }, []);
  return (
    <section style={{ background: "white !important", height: "100vh" }}>
      <div className="container" style={{ marginTop: "5vh" }}>
        {documentState.files.isLoading === true ? (
          <Spin tip="Loading">
            <div className="content" />
          </Spin>
        ) : (
          <Card>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {documentState.files.data.map((item) => (
                <span
                  style={{ padding: "20px", cursor: "pointer" }}
                  onClick={() => window.open(item.fileAccessURL)}
                  // onClick={() => getFiles(item._id)}
                >
                  <FileTextOutlined
                    twoToneColor="#124b81"
                    style={{ fontSize: "3rem", color: "#124b81" }}
                  />
                  <p>{item.fileName}</p>
                </span>
              ))}
            </div>
          </Card>
        )}
      </div>
    </section>
  );
}

export default Files;
