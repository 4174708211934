import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import base64 from "base-64";
import { ToastContainer, toast } from "react-toastify";

export const getDNAList = createAsyncThunk(
  "dna/get",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/dashboard/dna`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting know your DNA List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting dna list please Try Again Later"
      );
    }
  }
);
export const createDNA = createAsyncThunk(
  "dna/create",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/dashboard/dna`, data);
      dispatch(getDNAList());
      toast.success("Created successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in creating know your DNA List.", {
        position: "bottom-center",
      });
      return rejectWithValue("Error while creating dna please Try Again Later");
    }
  }
);

export const updateDNA = createAsyncThunk(
  "dna/update",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(`/dashboard/dna`, data);
      dispatch(getDNAList());
      toast.success("Updated successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in updating know your DNA List.", {
        position: "bottom-center",
      });
      return rejectWithValue("Error while updating dna please Try Again Later");
    }
  }
);

const initialState = {
  dna: {
    loading: false,
    data: [],
  },
};
export const dnaSlice = createSlice({
  name: "dna",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDNAList.fulfilled, (state, action) => {
        state.dna.data = action.payload;
        state.dna.loading = false;
      })
      .addCase(getDNAList.pending, (state, action) => {
        state.dna.loading = true;
      })
      .addCase(getDNAList.rejected, (state, action) => {
        state.dna.loading = false;
      });
  },
});

export const {} = dnaSlice.actions;
export default dnaSlice.reducer;
