/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from "react";
import { Upload, Card, Button, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import base64 from "base-64";
import { useSelector, useDispatch } from "react-redux";
import { getFolderList, uploadFile } from "../../redux/documentsSlice";
import { ToastContainer, toast } from "react-toastify";

const { Option } = Select;
const { Dragger } = Upload;
const Context = React.createContext({
  name: "Default",
});
export default function UploadFiles(props) {
  const [api, contextHolder] = notification.useNotification();
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const documentState = useSelector((state) => state.documents);
  const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
  const [category, setCategory] = useState(props.folderId);
  const [fileList, setFileList] = useState([]);
  const [, /* fileListBase64 */ setFileListBase64] = useState([]);
  const openNotification = (placement) => {
    api.info({
      message: `Notification ${placement}`,
      description: (
        <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
      ),
      placement,
    });
  };
  React.useEffect(() => {
    dispatch(getFolderList());
  }, []);
  function handleSubmit(event) {
    event.preventDefault();
    dispatch(uploadFile({ file, category, callback: uploadCallback }));
    dispatch(getFolderList());
  }
  function uploadCallback(upload) {
    console.log("Upload callback", upload);
    toast.success(upload.message, {
      position: "bottom-center",
    });
    // alert();
    setFile(null);
    setCategory(null);
    setFileList(null);
    setFileListBase64([]);
    // props.handleCallback();
    // openNotification("topRight");
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
    setCategory(value);
  }
  React.useEffect(() => {
    console.log("the file uploaded uia", file);
  }, [file]);

  const propsUpload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);

      return setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    onChange(info) {
      const listFiles = info.fileList;
      const newArrayFiles = listFiles.map((file) =>
        file.originFileObj ? file.originFileObj : file
      );
      setFile(newArrayFiles);

      const anAsyncFunction = async (item) => {
        return convertBase64(item);
      };

      const getData = async () => {
        return Promise.all(newArrayFiles.map((item) => anAsyncFunction(item)));
      };
      getData().then((data) => {
        setFileListBase64(data);
      });
    },
    fileList: file,
    multiple: true,
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader?.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <section
      style={{
        background: "white !important",
        marginTop: "15vh",
      }}
    >
      <Card style={{ height: "70vh", overflow: "auto" }}>
        {documentState.isLoading === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Spin indicator={antIcon} />
            <span style={{ fontSize: 20, marginLeft: 5 }}>
              Please wait uploading your files
            </span>
          </div>
        ) : (
          <>
            <div>
              <center>
                <h2>Please select the folder to upload from the down menu</h2>
              </center>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Select
                  style={{ width: 420 }}
                  placeholder="Please select the category of document"
                  onChange={handleChange}
                  value={category}
                  isLoading={documentState.folderList.isLoading}
                >
                  {documentState.folderList.data.map((item) => (
                    <Option value={item._id}>{item.folderName}</Option>
                  ))}
                </Select>
              </div>
              <div style={{ display: "flex", justifyContent: "center" , marginTop:'20px'}}>
                <Dragger {...propsUpload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    All the files uploaded here will be saved in cureonco
                    application and you can view them in mobile application
                    inside documents menu.
                  </p>
                </Dragger>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <Button
                onClick={(e) => handleSubmit(e)}
                type="primary"
                disabled={file && file.length > 0 && category ? false : true}
              >
                Upload
              </Button>{" "}
              &nbsp;&nbsp;&nbsp;
              {/* <Button onClick={() => setFileList(null)}>Cancel</Button> */}
            </div>
          </>
        )}
      </Card>
    </section>
  );
}
