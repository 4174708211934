import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import base64 from "base-64";
import { ToastContainer, toast } from "react-toastify";


export const getNews = createAsyncThunk(
  "news/getNews",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/onCoNews`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting news List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while fetching news please Try Again Later"
      );
    }
  }
);

export const updateNews = createAsyncThunk(
  "news/updateNews",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(`/onCoNews`, data);
      dispatch(getNews());
      toast.success("Updated successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in updating News.", {
        position: "bottom-center",
      });
        console.log('err', err)
      return rejectWithValue(
        "Error while updating news please Try Again Later"
      );
    }
  }
);

export const addNews = createAsyncThunk(
  "news/addNews",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/onCoNews`, data);
      dispatch(getNews());
      toast.success("Created successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in creating news.", {
        position: "bottom-center",
      });
      return rejectWithValue("Error while adding news please Try Again Later");
    }
  }
);
const initialState = {
    newsList: {
      loading: false,
      data: [],
    },
  };
  export const newsSlice = createSlice({
    name: "oncoNews",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getNews.fulfilled, (state, action) => {
          state.newsList.data = action.payload;
          state.newsList.loading = false;
        })
        .addCase(getNews.pending, (state, action) => {
          state.newsList.loading = true;
        })
        .addCase(getNews.rejected, (state, action) => {
          state.newsList.loading = false;
        });
    },
  });
  
  export const { toggleLoginModal } = newsSlice.actions;
  export default newsSlice.reducer;
  