import React from "react";
import aboutBg from "../../assets/images/about-bg.png";
import memeber1 from "../../assets/images/member-1.jpg";
import member2 from "../../assets/images/member-2.jpg";
import caseStudy from "../../assets/images/caseStudy.svg";

function Casestudy() {
  return (
    <>
      <section className="page-header-section">
        <div className="container page-header-container-divided">
          <div className="page-header-content">
            {/* <p className="page-header-badge">Case Studies</p> */}
            <h1 className="xl-text b-weight inverted-text r-b-m">
              We&apos;ve set out to power purpose in every business worldwide
            </h1>
            <p className="r-text inverted-text ah-op">
              Manage your treatment data, documents and chat with groups.
              Centralised system to manage your data securely.
            </p>
          </div>
          <div className="page-header-img-container">
            <img src={aboutBg} alt="background" className="about-bg" />
          </div>
        </div>
      </section>

      <section className="case-study-section">
        <div className="sm-container xxxxl-t-m">
          <div className="expanded-case-study-item">
              <img
                src={caseStudy}
                alt="member"
                className="expanded-case-study-img"
              />

            <div className="expanded-case-study-content">
              <p className="xs-text ah-op">Jan 20, 2022</p>
              <p className="lg-text b-weight">Hydrotherapy and Cancer</p>
              <p className="ah-op r-text">
                Hydrotherapy, also referred to as water or aquatic therapy, is a
                rehabilitation method for many different situations. Many users
                in various groups within the Belong app discuss hydrotherapy as
                a helpful complementary treatment for dealing with stress,
                fatigue, and other side effects and symptoms. Hydrotherapy,
                performed by a knowledgeable and qualified practitioner, might
                have several benefits for oncology patients. It can improve
                breathing and blood circulation, relax muscles and reduce
                cramps.
              </p>
              <a href="/about" className="case-study-btn">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="case-study-list-section">
        <div className="sm-container">
          <div className="case-study-grid">
            <div className="case-study-item">
              <div className="case-study-img">
                <img src={memeber1} alt="member" />
              </div>
              <div className="case-study-content">
                <p className="xs-text ah-op">Jan 20, 2022</p>
                <p className="r-text b-weight">
                  Olivia Taylor - Thyroid Cancer Survivor
                </p>
                <p className="ah-op xs-text">
                  By Dr. Daniel Vorobiof, Chief Medical Director of Belong.Life.
                  For the past 20 years there have been…
                </p>
                <a className="case-study-btn">Learn more</a>
              </div>
            </div>
            <div className="case-study-item">
              <div className="case-study-img">
                <img src={member2} alt="memeber2" />
              </div>
              <div className="case-study-content">
                <p className="xs-text ah-op">Jan 20, 2022</p>
                <p className="r-text b-weight">
                  Neelakandan - Colorectal Cancer Survivor
                </p>
                <p className="ah-op xs-text">
                  By Dr. Daniel Vorobiof, Chief Medical Director of Belong.Life.
                  For the past 20 years there have been…
                </p>
                <a className="case-study-btn">Learn more</a>
              </div>
            </div>
            <div className="case-study-item">
              <div className="case-study-img">
                <img src={member2} alt="member" />
              </div>
              <div className="case-study-content">
                <p className="xs-text ah-op">Jan 20, 2022</p>
                <p className="r-text b-weight">
                  Resistance Training Relieves Fatigue
                </p>
                <p className="ah-op xs-text">
                  Prior research has demonstrated the benefits of exercise on
                  MS-related fatigue. In a recent study…
                </p>
                <a className="case-study-btn">Learn more</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Casestudy;
